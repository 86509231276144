@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

input {
  caret-color: red;
}

.show{
    display: none;
}

body{
  background-image: url('https://i.pinimg.com/550x/05/02/9c/05029ce653e4f954e9ae29f1f4184a00.jpg');
 
  background-size: cover;
}
.form__container{
  position: relative;
}

.container {
  position: fixed;
  left: 40%;
  top: 40%;
  bottom: 50%;
  width: 350px;
  height: 500px;
  border-radius: 20px;
  padding: 40px;
  box-sizing: border-box;
  background: #ecf0f3;
  margin: auto;
  
}

.brand-logo {
  height: 100px;
  width: 100px;
  margin: auto;
  border-radius: 50%;
  box-sizing: border-box;

}

.brand-title {
  margin-top: 10px;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1DA1F2;
  letter-spacing: 1px;
}

.inputs {
  text-align: left;
  margin-top: 30px;
}

.form__label, .form__input, .form__btn {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}




.inputs label {
  margin-bottom: 4px;
}

.inputs label:nth-of-type(2) {
  margin-top: 12px;
}

.inputs input::placeholder {
  color: gray;
}

.inputs input {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}



.inputs button {
  color: white;
  margin-top: 20px;
  background: #FC1503;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 900;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
  transition: 0.5s;
  position: relative;
}

.inputs button:hover {
  box-shadow: none;
}

.form__link {
  position: absolute;
  font-size: 8px;
  bottom: 4px;
  right: 4px;
  text-decoration: none;
  color: black;
  background: yellow;
  border-radius: 10px;
  padding: 2px;
}

.brand-title{
  position: absolute;
  top: 85%;
  left: 26%;
  color: #FC1503;

  
}



@media  screen and (max-width: 600px) {
  .container {
    position: fixed;
    left: 30px;
    margin: auto;
    width: 300px;
    height: 450px;
    border-radius: 20px;
    padding: 30px;

  
  }
}